import React from 'react'

import './index.scss'

export const Footer = () => (
  <footer className="footer">
    <p>
      © <a href="https://dennis-adamczyk.de">Dennis Adamczyk</a> 2021
    </p>
    <ul>
      <li>
        <a href="https://dennis-adamczyk.de/imprint">Impressum</a>
      </li>
      <li>
        <a href="https://dennis-adamczyk.de/privacy">Datenschutz</a>
      </li>
    </ul>
  </footer>
)
